<template>
  <nuxt-link
    :to="localePath('/post/' + postData?.slug)"
    class="xs:item-container flex flex-col border-transparent xs:border-gray border-solid relative w-[auto] h-auto xs:h-[455px] p-0 xs:p-5 hover:text-orange hover:cursor-pointer overflow-hidden"
  >
    <div class="w-[auto] lg:h-[180px] h-[210px] mx-auto mb-[15px]">
      <nuxt-img
        :src="postData?.image || NO_IMAGE_PATH"
        class="m-auto max-h-[180px] object-contain"
        width="420"
        height="180"
        loading="lazy"
        format="webp"
      />
      <!-- <div class="swiper-lazy-preloader"></div> -->
    </div>

    <div class="flex flex-col flex-1 mb-auto">
      <div class="font-semibold text-[15px] text-green-evergreen line-clamp-2">
        {{ postData?.name }}
      </div>

      <div
        class="text-sm mt-3 text-green-swamp line-clamp-4 h-[80px] max-h-28 md:max-h-none news-swiper-content"
        v-html="postData?.shortContent"
      ></div>
    </div>
    <div
      class="p-2.5 text-green-swamp text-xs w-fit rounded mt-2.5 mb-4 bg-green/10"
    >
      {{ label }}
    </div>

    <div class="flex justify-between text-xs text-gray-mud">
      <div class="flex">
        <IconsCalendar class="h-4 w-4 mr-2" />
        <span v-if="props.data?.saleDateFrom || props.data?.saleDateTo">
          <span
            v-if="props.data?.saleDateFrom"
            class="mr-1"
          >{{ t('from') }}
            {{ props.data?.getPromotionDate(props.data?.saleDateFrom) }}</span>
          <span v-if="props.data?.saleDateTo">{{ t('to') }}
            {{ props.data?.getPromotionDate(props.data?.saleDateTo) }}</span>
        </span>
        <span v-else>{{ props.data?.getDate && props.data?.getDate() }}</span>
      </div>

      <!--      <div class="flex">-->
      <!--        <IconsEye class="h-4 w-4 mr-2" />-->
      <!--        {{ props.data.views }}-->
      <!--      </div>-->
    </div>
  </nuxt-link>
</template>

<script setup>
import get from 'lodash/get'

const { t } = useI18n()

const props = defineProps({
  data: {
    type: Object,
    default: () => ({})
  }
})

const postData = computed(() => props.data)
const label = computed(() => get(props.data, ['taxons', '0', 'name'], 'Новини'))
</script>

<style scoped>
.item-container:hover {
  box-shadow: 0 10px 40px rgba(38, 38, 38, 0.05),
    0 2px 4px rgba(38, 38, 38, 0.02), 0 10px 40px rgba(38, 38, 38, 0.05),
    0 2px 4px rgba(38, 38, 38, 0.02);
  background: #ffffff;
}
</style>

<style lang="postcss">
/* fixes for mobile safari */
.news-swiper-content img {
  @apply hidden;
}
.news-swiper-content * {
  @apply inline;
}
</style>
