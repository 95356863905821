<template>
  <ListHorizontal
    v-if="newsList?.length"
    :title="props.title"
    :sub-title="props.subTitle"
    :list-link="listLink"
    base-slide-width="380"
    :slides="newsList"
    :slides-per-view="1"
    :breakpoints="{
      900: {
        slidesPerView: 2
      },
      1200: {
        slidesPerView: 3
      },
      1500: {
        slidesPerView: 4
      }
    }"
  >
    <template #default="{ slide: news }">
      <Item
        class="first:border-y border-l last:border-r mb-1.5 xs:mb-5"
        :data="news"
      />
    </template>
  </ListHorizontal>
  <div v-else class="h-[370px] w-full animate-pulse"></div>
</template>

<script setup>
import Item from './Item.vue'
import { useNewsStore } from '~/stores/news'

const newsStore = useNewsStore()

const props = defineProps({
  title: {
    type: String,
    default: ''
  },
  subTitle: {
    type: String,
    default: ''
  },
  listLink: {
    type: Object,
    default: null
  }
})

const newsList = computed(() => newsStore.newsList || [])

const pagination = ref({
  page: 1,
  itemsPerPage: 20
})

useAsyncData('news-home', () => newsStore.fetchNews(pagination.value))
</script>
